<template>
  <main class="main inside-page form-page rooms-page chat-page">
    <div class="section chat-section">
      <div class="wrapper">
        <div class="top">
          <div class="title">
            <img class="img" src="../assets/decor15.svg"/>
            <img class="img mob" src="../assets/decor6Mob.png"/>
            <span>{{ topic.title }}</span>
          </div>
          <div class="right">
            <button @click="leaveChat" class="button button-red">{{ topic.is_owner ? $t('CLOSE CHAT') : $t('LEAVE CHAT') }}</button>
            <div v-if="participantsCount >= 2" class="button-settings-container">
              <button class="button button-settings">{{ $t('SETTINGS') }}</button>
              <div class="button-settings-dropdown">
                <div class="button-settings-dropdown-wrapper">
                  <button v-if="!topic.is_owner" class="desc" @click="reportType = 'topic'; showReportModal = true">{{ $t('Report this topic') }}</button>
                  <button class="desc" @click="reportType = 'user'; showReportModal = true">{{ $t('Report this user') }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="chat-container is-downloaded-images">
          <div class="chat">
            <div class="chat-top">
              <div class="user-main">
                <img class="userNameBg" src="../assets/userNameBg.png"/>
                <img class="userNameBg mob" src="../assets/userNameBgMob.svg"/>
                <div v-if="topic.id" class="desc">{{ topic.companion_name }}</div>
              </div>
              <div v-if="participantsCount >= 2" class="online">
                <img class="online-img" src="../assets/online.svg"/>
                <div class="desc desc-online">{{ $t('online') }}</div>
              </div>
            </div>
            <div class="chat-content">
              <div @scroll="handleScroll" ref="messages" class="chat-content-wrapper">
                <div class="list">
                  <div v-for="({content, id, sender, attachments}) in messages" :key="id" class="item">
                    <div class="name desc">{{ sender }}</div>
                    <div class="message desc">{{ content }}</div>
                    <div class="message-img-list"> <!-- if image -->
                      <img v-for="({path}) in getImageAttachments(attachments)" :key="path" @click="imagePreview(path)" class="message-img" :src="path"/>
                    </div>

                    <div class="message-doc-list">  <!-- if doc -->
                      <div v-for="({original_filename, path, filesize}) in getDocumentAttachments(attachments)" :key="path" class="doc">
                        <img class="doc-img" @click="openOrDownloadFile(path)" src="../assets/text.png"/>
                        <div class="text">
                          <div class="desc">{{ original_filename }}</div>
                          <div v-if="filesize" class="desc">{{ humanFileSize(filesize) }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ValidationObserver slim v-slot="{ invalid, pristine, handleSubmit }">
              <form ref="form" @submit.prevent="handleSubmit(sendMessage)" class="chat-bottom">
                <div class="chat-info">
                  <div class="left">
                    <label class="camera">
                      <input :disabled="participantsCount < 2" accept="image/*" @change="uploadFile('imageFile')" ref="imageFile" type="file"/>
                      <img class="img" src="../assets/camera.svg"/>
                    </label>
                    <label class="clip">
                      <input :disabled="participantsCount < 2" @change="uploadFile('documentFile')" ref="documentFile" type="file"/>
                      <img class="img" src="../assets/clip.svg"/>
                    </label>
                  </div>
                  <div class="right">
                    <div class="desc">
                      {{ $t('CURRENT BALANCE') }} : <span>{{ balance }} </span> {{ $t('POINTS') }}
                    </div>
                  </div>
                </div>
                <div class="downloaded-images">
                  <div class="downloaded-images-wrapper">
                    <div v-if="documentFileData" class="downloaded-images__item">
                      <img @click="openOrDownloadFile(documentFileData.link)"  class="img" src="../assets/text.png"/>
                      <div class="text">
                        <div class="desc">{{ documentFileData.name }}</div>
                        <img @click="clearFile('documentFile')" class="bin" src="../assets/bin.svg"/>
                      </div>
                    </div>
                    <div v-if="imageFileData" class="downloaded-images__item">
                      <img class="img" @click="imagePreview(imageFileData.link)" :src="imageFileData.link"/>
                      <div class="text">
                        <div class="desc">{{ imageFileData.name }}</div>
                        <img @click="clearFile('imageFile')" class="bin" src="../assets/bin.svg"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="message-area">
                  <div class="left">
                    <ValidationProvider rules="" slim vid="message">
                      <label>
                        <textarea @keydown.enter.prevent="handleSubmit(sendMessage)" :disabled="participantsCount < 2" v-model="message" name="message" class="desc" type="text"
                                  :placeholder="participantsCount >= 2 ? $t('START TYPING......') : $t('Waiting for the other person join this chat')"/>
                      </label>
                    </ValidationProvider>
                  </div>
                  <div class="right">
                    <button class="button">
                      <img @click="submit(!isAllowedToSendMessage)" class="img" :class="{'disabled': !isAllowedToSendMessage}"
                           src="../assets/envelope.svg"/>
                      <button :disabled="!isAllowedToSendMessage" ref="submitMessage" type="submit"
                              style="display: none"></button>
                    </button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <ReportModal @apply="sendReport" @close="showReportModal = false" v-if="showReportModal" />
    </transition>
    <vue-easy-lightbox
        :visible="imagePreviewVisible"
        :imgs="images"
        :index="index"
        @hide="hideImagePreview"
    ></vue-easy-lightbox>
  </main>
</template>


<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import axios from "@/axios";
import router from "@/router";
import {humanFileSize} from "@/utils";
import ReportModal from "@/components/ReportModal.vue";
import VueEasyLightbox from 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.esm.min.js'
import 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.css'

export default {
  name: "Login",
  props: ['chatNoteModalIsVisible'],
  components: {
    ValidationProvider,
    ValidationObserver,
    ReportModal,
    VueEasyLightbox
  },
  data: function () {
    return {
      intervalId: null,
      participantsIntervalId: null,
      message: '',
      messages: [],
      page: 1,
      pageSize: 25,
      lastPage: null,
      topic: {},
      loadingMessages: false,
      documentFile: null,
      imageFile: null,
      documentFileData: null,
      imageFileData: null,
      showReportModal: false,
      reportType: null,
      imagePreviewVisible: false,
      images: [],
      index: 0
    };
  },
  async created() {
    if (!this.topicId) {
      await router.push('/rooms')
      return;
    }

    const self = this;

    // const messages = this.getMessages().then(({data}) => {
    //   if (data.status === 'OK') {
    //     self.messages = data.payload.data;
    //     self.lastPage = data.payload.meta.last_page;
    //   }
    // });

    // await Promise.all([this.getTopic(), this.newMessages()])

    await this.getTopic();

    if (self.participantsCount < 2) {
      self.setUpParticipantsPooling();
    }

    if (!this.isCurrentChatAgreed) {
      this.$parent.openChatNoteModal(this.topic.id);
    } else {
      await this.newMessages();
      this.setUpNewMessagePooling();
    }

    // const newMessages = this.newMessages();

    // await newMessages;
  },
  // mounted() {
  //   this.$parent.openChatNoteModal();
  // },
  beforeDestroy() {
    this.clearMessagePooling()
    this.clearParticipantsPooling()
  },
  unmounted() {
    this.clearMessagePooling()
    this.clearParticipantsPooling()
  },
  methods: {
    scrollToEnd() {
      const scrollableDiv = this.$refs.messages;
      scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
    },
    imagePreview(src) {
      this.images = [src];
      this.imagePreviewVisible = true;
    },
    hideImagePreview() {
      this.imagePreviewVisible = false;
    },
    sendReport() {
      const self = this;

      axios.post('reports/create', {
        topic_id: self.topic.id,
        type: self.reportType
      }).then(async ({data}) => {
        if (data.status === 'OK') {
          self.showNotification({text: this.$t("Your report has been successfully sent. Our team will review this report shortly")})
          await router.push({name: 'Topics', params: {name: this.$route.params.name}})
        }
      }).finally(() => {
        self.showReportModal = false;
      });

      //     .then(({data}) => {
      //   if (data.status === 'OK') {}
      // })
    },
    humanFileSize,
    openOrDownloadFile(path) {
      // Attempt to open the file in a new window
      const newWindow = window.open(path, '_blank');

      // Check if the new window is opened
      if (newWindow) {
        // Focus the new window
        newWindow.focus();
      } else {
        // If the browser blocks opening the new window, download the file in the current window
        this.downloadFileInCurrentWindow(path);
      }
    },
    downloadFileInCurrentWindow(fileUrl) {
      // Create a temporary link element
      const link = document.createElement('a');
      link.href = fileUrl;

      // Simulate clicking the link to trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up after download
      document.body.removeChild(link);
    },
    getImageAttachments(attachments) {
      return attachments.filter(({type}) => type === 'image');
    },
    getDocumentAttachments(attachments) {
      return attachments.filter(({type}) => type === 'document');
    },
    uploadFile(type) {
      console.log(type, this.$refs[type]);
      const file = this.$refs[type].files[0];
      console.log(file, 'file');
      this.$data[type] = file;
      this.$data[`${type}Data`] = {
        link: window.URL.createObjectURL(file),
        name: file.name,
        fileSize: humanFileSize(file.size)
      };
    },
    clearFile(type) {
      this.$data[type] = null;
      this.$data[`${type}Data`] = null;
    },
    async goToTopics() {
      await router.push({name: 'Topics', params: {name: this.$route.params.name}})
    },
    leaveChat() {
      const self = this;

      axios.post('topics/leave', {
        topic_id: self.topic.id
      }).then(({data}) => {
        if (data.status === 'OK') {
          self.clearMessagePooling();
          self.clearParticipantsPooling()
          self.goToTopics()
        }
      })
    },
    setUpNewMessagePooling() {
      clearInterval(this.intervalId);
      const self = this;

      this.intervalId = setInterval(() => {
        if (!self.loadingMessages) {
          self.newMessages()
        }
      }, 5000);
    },
    setUpParticipantsPooling() {
      clearInterval(this.participantsIntervalId);
      const self = this;

      this.participantsIntervalId = setInterval(async () => {
        if (self.participantsCount < 2) {
          await self.getTopic();
        } else {
          self.clearParticipantsPooling();
        }
      }, 5000);
    },
    clearParticipantsPooling() {
      clearInterval(this.participantsIntervalId);
    },
    clearMessagePooling() {
      clearInterval(this.intervalId);
    },
    getTopic() {
      const self = this;
      
      return axios.get('topics/topic', {
        params: {
          topic_id: this.topicId
        }
      }).then(({data}) => {
        if (data.status === 'OK') {
          self.topic = data.payload;
          setTimeout(function() {
            self.scrollToEnd();
          },300)
        }
      }).catch(({response}) => {
        if (response.status === 404) {
          self.goToTopics();
        }
      })
    },
    submit(disabled) {
      if (!disabled) {
        try {
          this.$refs.form.$el.submit()
          // eslint-disable-next-line no-empty
        } catch (e) {}
      }
    },
    async sendMessage() {
      const self = this;
      this.clearMessagePooling();

      const formData = new FormData();
      formData.set('topic_id', this.topicId);
      formData.set('content', this.message);

      if (this.documentFile) {
        formData.append('attachments[]', this.documentFile);
        formData.append('types[]', 'document');
      }

      if (this.imageFile) {
        formData.append('attachments[]', this.imageFile);
        formData.append('types[]', 'image');
      }

      await axios.post('messages/create', formData).then(({data}) => {
        if (data.status === 'OK') {
          self.clearChatInputs();
          const message = data.payload.message;

          if (!self.messages.some(({id}) => id === message.id)) {
            self.messages = [message, ...self.messages]
          }

          self.SET_BALANCE(data.payload.balance);
          setTimeout(() => {
            self.scrollToEnd();
          }, 100);
          
        }
      }).catch(({response}) => {
        if (response.status === 402) {
          self.showNotification({text: response.data.message, ok: true})
        }
      }).finally(() => {
        self.newMessages()
        self.setUpNewMessagePooling()
      });
    },
    clearChatInputs() {
      this.message = '';
      this.imageFile = null;
      this.documentFile = null;
      this.imageFileData = null;
      this.documentFileData = null;
    },
    async getMessages(page = null, pageSize = null) {
      const data = ['messages/index', {
        params: {
          topic_id: this.topicId,
          order_by: 'id',
          page_size: pageSize || this.pageSize,
          page: page || this.page
        }
      }];

      return axios.get(...data)
    },
    async newMessages(pageSize = null) {
      const self = this;
      this.loadingMessages = true;

      return this.getMessages(1, pageSize).then(({data}) => {
        if (data.status === 'OK') {
          const messages = data.payload.data.filter(message => !self.messages.some(existingMessage => existingMessage.id === message.id));
          self.messages = [...messages, ...self.messages]
          self.lastPage = data.payload.meta.last_page;
        }
      }).catch(async ({response}) => {
        if (response.status === 404) {
          const payload = response.data.payload;

          if (payload && payload.closed) {
            self.clearMessagePooling()
            self.clearParticipantsPooling()
            self.$parent.closeChatNoteModal();
            self.goToTopics()
            await self.showNotification({text: response.data.message});
          }
        }
      }).finally(() => {
        self.loadingMessages = false;
      })
    },
    async handleScroll() {
      const self = this;
      const messagesEl = this.$refs.messages;
      if (messagesEl.scrollTop >= messagesEl.scrollHeight - messagesEl.clientHeight && this.page < this.lastPage) {
        this.loadingMessages = true;
        this.page++;
        await this.getMessages().then(({data}) => {
          if (data.status === 'OK') {
            self.messages = [...self.messages, ...data.payload.data];
            self.lastPage = data.payload.meta.last_page;
          }
        }).catch(async ({response}) => {
          if (response.status === 404) {
            const payload = response.data.payload;

            if (payload && payload.closed) {
              self.clearMessagePooling()
              self.clearParticipantsPooling()
              self.$parent.closeChatNoteModal();
              self.goToTopics()
              await self.showNotification({text: response.data.message});
            }
          }
        }).finally(() => {
          self.loadingMessages = false
        })
      }
    },
    ...mapActions('app', ['showNotification']),
    ...mapMutations('user', ['SET_BALANCE'])
  },
  computed: {
    isAllowedToSendMessage() {
      return this.participantsCount >= 2 && (this.documentFile || this.imageFile || this.message);
    },
    isCurrentChatAgreed() {
      return this.isChatAgreed(this.topic.id);
    },
    participantsCount() {
      if (this.topic) {
        return this.topic.participants_count;
      }

      return 0;
    },
    ...mapGetters('user', ['balance']),
    ...mapGetters('topics', ['topicId']),
    ...mapGetters('topics', ['chatAgreed', 'isChatAgreed']),
  },
  watch: {
    chatNoteModalIsVisible(newValue) {
      if (!newValue) {
        this.newMessages();
        this.setUpNewMessagePooling();
      }
    }
  }
};
</script>
